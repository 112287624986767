
.map-infowindow{
    button{
        background-color: transparent;
        border: none;
        padding: 0;
        em{
            font-size: 23px;
        }
    }
    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 10px;
        em {
            font-size: 20px;
        }
    }
}

@media screen and (min-width: 700px) {
    .map-infowindow{
        display: flex;
        gap: 20px;
    }
  }
  

.gm-style-iw,.gm-style-iw-c{
    max-height: 300px !important;
}

.gm-style-iw-d{
    max-height: 300px !important;
}

.map-image{
    height: 85px;
    width: 137px;
    margin-top: 10px;
    border-radius: 10px;
    cursor: zoom-in;
}

.map-title{
    margin: 10px 0 15px 0;
}

.map-text{
    margin-top: 5px;
}

.map-buttons{
    margin-top: 10px;
    display: flex;
    justify-content: center;
    gap: 5px;
}

.gm-ui-hover-effect {
    width: 20px !important;
    height: 20px !important;
    span {
        width: 16px !important;
        height: 16px !important;
        margin: 4px 4px 0 0 !important;
    }
}