/* displays */
.flex {
  display: flex;
}

/* display-flex */
.space-between {
  justify-content: space-between;
}
.space-around {
  justify-content: space-around;
}
.space-evenly {
  justify-content: space-evenly;
}
.flex-end {
  justify-content: end;
}
.flex-center {
  justify-content: center;
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: flex-end;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.gap-10 {
  gap: 10px;
}
.gap-20 {
  gap: 20px;
}
.gap-70 {
  gap: 70px;
}

.grid {
  display: grid;
}

.columns-2 {
  display: grid;
  grid-template-columns: 100%;
}

.top-right-absolute {
  position: absolute;
  top: 20px;
  right: 24px;
}

@media screen and (min-width: 700px) {
  .columns-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: lightgray #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  height: 2px;
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 10px;
}
