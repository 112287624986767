/* animations */

.animated {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.slow {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
.animated.gray {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
  }
  80% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpBig {
  animation-name: fadeInUpBig;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}
