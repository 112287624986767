.auth-page {
  background-image: url(../../assets/images/login-background.png);
  background-size: cover;
  background-color: #0877a5;
  height: calc(100vh - 60px);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px !important;

  .background-logo {
    position: absolute;
    top: calc(10% - 30px);
    left: 32px;
    width: 50%;
    height: 80%;
    > img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
      object-fit: contain;
      opacity: 0.2;
    }
  }

  .auth-card {
    width: 90%;
    max-width: 424px;
    position: relative;
    margin: auto;
    .be-everywhere-logo {
      margin: 24px 0;
      width: 100%;
      height: auto;
      > img {
        width: 100%;
        max-width: 272px;
        height: auto;
        object-fit: contain;
      }
    }
  }
}

.password-eye-icon {
  width: 24px;
  height: auto;
  margin-top: 5px;
}
