/* text colors */

.text-primary {
  color: var(--app-primary-color) !important;
}
.text-secondary {
  color: var(--app-secondary-color) !important;
}
.text-dark {
  color: var(--app-dark-color);
}
.text-light {
  color: var(--app-light-color);
}
.text-danger {
  color: var(--app-danger-color);
}
.text-success {
  color: var(--app-success-color);
}
.text-warning {
  color: var(--app-warning-color);
}
.text-gray {
  color: var(--app-gray-color);
}
.text-medium-gray {
  color: var(--app-medium-gray-color);
}
.text-light-gray {
  color: var(--app-light-gray-color);
}
.text-dark-gray {
  color: var(--app-dark-gray-color);
}

/* background colors */

.background-primary {
  background: var(--app-primary-color);
}
.background-gradient-nav{
  background: var(--app-gradient-nav-color);
}
.background-secondary {
  background: var(--app-secondary-color);
}
.background-primary-opacity {
  background: var(--app-primary-opacity-color);
}
.background-orange {
  background: var(--app-orange-color);
}
.background-dark {
  background: var(--app-dark-color);
}
.background-gray {
  background: var(--app-gray-color);
}
.background-medium-gray {
  background: var(--app-medium-gray-color);
}
.background-light {
  background: var(--app-light-color);
}
.background-light-green {
  background: var(--app-light-green-color);
}
.background-danger {
  background: var(--app-danger-color);
}
.background-stripe {
  background: var(--app-stripe-color);
}
.background-transparent {
  background: transparent;
}
.background-gradient-primary {
  background: var(--app-gradient-primary-color);
}

/* font weight */

.font-weight-bold {
  font-weight: bold;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-800 {
  font-weight: 800;
}
.font-weight-normal {
  font-weight: normal;
}

/* text-align */

.text-align-start {
  text-align: start;
}
.text-align-center {
  text-align: center;
}
.text-align-end {
  text-align: end;
}

/* cursor */

.pointer {
  cursor: pointer;
}

/* dropdown */
.dropdown {
  position: relative;
  cursor: pointer;
}

.dropdown-content {
  padding: 0px;
  margin: 0;
  right: 0;
  top: 20px;
  position: absolute;
  background-color: var(--app-light-color);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  z-index: 2;
}

.dropdown-content div {
  padding: 20px;
}
.dropdown-content div:hover {
  background: var(--app-light-gray-color);
}

.badges-container {
  gap: 10px;
}

.badge {
  width: 156px;
  height: 35px;
  padding: 5px 0;
  font-size: 18px;
}

.badge span {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: -5px;
}

.badge em {
  font-size: 25px;
  margin: 0 10px;
}

.common-icon{
  font-size: 23px;
  margin-left: 5px;
}

.disable{
  pointer-events: none;
  opacity: 0.8;
}

/**Utilities*/
.list-style-inside{
  list-style-position: inside;
}

.common-font-size{
  font-size: 16px;
}

/**Margin*/
.margin-top-20 {
  margin-top: 20px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
